.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.login-image {
  background: url('https://imageslsdt.s3.eu-west-3.amazonaws.com/Salle/Clichy/LSDT-CLICHY-INSIDE.jpg') no-repeat center center;
  background-size: cover;
  /* background-size: 100vh; */

  /* height: 100vh;
	background-attachment: fixed;
	border: 0; */
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.video-responsive { 
  overflow:hidden; 
  padding-bottom:56.25%; 
  position:relative; 
  height:0;
}

.video-responsive iframe {
  left:0; 
  top:0; 
  height:100%;
  width:100%;
  position:absolute;
}
/* react-datepicker-wrapper > div.react-datepicker__input-container {
  width: 100%;
} */

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}